import './types'
import './style.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { Suspense, useEffect, useState } from 'react'

import { Configuration } from './configuration'

const Login = React.lazy(() => import('./apps/login'))
const Omise = React.lazy(() => import('./apps/omise'))

const configuration = new Configuration()

type Status = 'FAILED' | 'READY' | 'LOADING'

function App() {
	const [status, setStatus] = useState<Status>('LOADING')

	useEffect(() => {
		configuration
			.load()
			.then(() => {
				setStatus('READY')
			})
			.catch(() => setStatus('FAILED'))
	}, [])

	if (status === 'FAILED') {
		return (
			<div className='status'>
				<h1>404 - PAGE NOT FOUND</h1>
				<p>
					The page you are looking for might have been removed had its name changed or it
					temporarily unavailable
				</p>
			</div>
		)
	}

	if (status === 'LOADING') {
		return <div className='status'></div>
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/omise'
					element={
						<Suspense fallback={<div />}>
							<Omise conf={configuration} />
						</Suspense>
					}
				/>
				<Route
					path='/auth'
					element={
						<Suspense fallback={<div />}>
							<Login />
						</Suspense>
					}
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default App
