import { Context, Omise } from './types'
import { JwtPayload, parseJwt } from './utils/parseJwt'
import { Params, getParams } from './utils/getParams'

const DOMAIN = process.env.REACT_APP_GLOBAL_DOMAIN || process.env.REACT_APP_DEFAULT_DOMAIN
const PROTOCAL = process.env.REACT_APP_GLOBAL_PROTOCAL || process.env.REACT_APP_DEFAULT_PROTOCAL

class Configuration {
	params: Params = {}
	message?: Omise
	context?: Context
	jwtPayload: JwtPayload = {}
	token: string

	constructor() {
		this.params = getParams(window.location.search)
		this.token = this.params.access_token
		this.jwtPayload = parseJwt(this.params.access_token)
	}
	async load(): Promise<void> {
		return fetch(
			`${PROTOCAL}://api.${DOMAIN}/line/apps/${this.jwtPayload.app_slug}/current-message?access_token=${this.params.access_token}`
		)
			.then((res) => {
				if (!res.ok) {
					throw new Error('HTTP status code: ' + res.status)
				}

				return res.json()
			})
			.then((data) => {
				this.message = data.message.config
				this.context = data.context
			})
	}
}

export { Configuration }
