export interface Params {
	[param: string]: string
}

export const getParams = function (url: string): Params {
	const params = {} as Params
	const parser = document.createElement('a')
	parser.href = url
	const query = parser.search.substring(1)
	const vars = query.split('&')
	vars.forEach((_, i) => {
		const pair = vars[i].split('=')
		params[`${pair[0]}`] = decodeURIComponent(pair[1])
	})
	return params
}
